import LocationDefaultQuery from '~/graphql/queries/location/default.graphql';
import LocationSubPageQuery from '~/graphql/queries/location/subPage.graphql';
import { useQuery } from '~/composables/villus';

export async function useLocationQuery() {
    const route = useRoute();
    const uri = useParseRoute(route);
    const variables = {
        uri
    };

    return await useQuery(
        LocationDefaultQuery,
        variables
    );
}

export async function useLocationSubPageQuery() {
    const route = useRoute();
    const uri = useParseRoute(route);

    const variables = {
        uri
    };

    return await useQuery(
        LocationSubPageQuery,
        variables
    );
}
